@tailwind base;

@import '@fontsource/dm-sans/index.css';
@import 'react-virtualized/styles.css';
@import './variables.css';

@tailwind components;

@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Play', sans-serif;
}

html,
body {
  font-family: 'Play', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
}

@media (max-width: 1080px) {
  body{
    background-image: url("/images/background-mobile.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-color: var(--primary);
  
  }
}

@media (min-width: 1081px) {
  body{
    background-image: url("/images/background.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: var(--primary);
  
  }
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.backdrop-blur-input  {
  background: rgba(0, 0, 0, 0.30);
}


.test-blur{
  background: rgba(0, 0, 0, 0.48);
  mix-blend-mode: normal;
  backdrop-filter: blur(120px);
}
.text-bg-blur {
  background: rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(120px);
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0.625rem;
  border: 1.5px solid rgba(255, 255, 255, 0.1);
  
}

.backdrop-blur-border{
  background: rgba(255, 255, 255, 0.21);
  backdrop-filter: blur(12px);
}

.heading-blur{
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(200px);
}

.border-input {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
}

.backdrop-blur-highlight  {
  background: rgba(255,209,102, 0.5);
  mix-blend-mode: normal;
  backdrop-filter: blur(120px);
}

 @media only screen and (min-width: 1080px) {
  .backdrop-blur  {
    background: rgba(0, 0, 0, 0.48);
    mix-blend-mode: normal;
    backdrop-filter: blur(120px);
  }
}
@media only screen and (max-width: 1080px) {
  .backdrop-blur {
    background: rgba(0, 0, 0, 0.2);
    mix-blend-mode: normal;
    backdrop-filter: blur(60px);
  }
}
.footer-bg {
  background: rgba(0, 0, 0, 0.18);
  mix-blend-mode: normal;
  backdrop-filter: blur(60px);
}

#connect-wallet{
  background-image: linear-gradient(to right, #00F0FF , #FF00E5, #FFF500);
  color: white;
}

.primary-btn{
  background-image: linear-gradient(to right, #00F0FF , #FF00E5, #FFF500);
  color: white;
}

.search-bar-color{
  /* background-color: #313132; */
  /* background: rgba(0, 0, 0, 0.40); */
  background: rgba(0, 0, 0, 0.6);
  mix-blend-mode: normal;
  backdrop-filter: blur(120px);
}  
.background-color-onsen{
  background-color: #313132;

}

#connect-wallet:hover{
  background: linear-gradient(to right, #6ae8f1 , #eb6cde, #e2de65);
  color: white;
}
  
button:focus {
  outline: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.striped-background {
  background: repeating-linear-gradient(45deg, #161522, #161522 10px, #0d0415 10px, #0d0415 20px);
}

/**::-webkit-scrollbar {*/
/*  width: var(--scrollbar-width);*/
/*}*/

/*::-webkit-scrollbar-track-piece:start {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/*::-webkit-scrollbar-track-piece:end {*/
/*  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAQAAAAD+Fb1AAAADklEQVR42mNkgAJG3AwAAH4ABWjFc8IAAAAASUVORK5CYII=) repeat-y !important;*/
/*}*/

/**::-webkit-scrollbar-thumb {*/
/*  border: 3px solid rgba(0, 0, 0, 0);*/
/*  background-clip: padding-box;*/
/*  border-radius: 9999px;*/
/*  background-color: #BFBFBF;*/
/*}*/

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  background-image: url('../../public/images/datepicker-icon.svg')
}
.sushi-icon{
  background-image: url('../../public/images/fermion.svg')
}
#sushi-icon{
  width: 62px;
  height: 62px;
  background-image: url('../../public/images/fermion.svg')
  
  /* width="62px"
  height="62px"
  objectFit="contain" */
}
/* if backdrop support: very transparent and blurred */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur-fallback {
    background-color: var(--primary);
  }
}

.text-low-emphesis{
  --tw-text-opacity: 0.6;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-high-emphesis{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.h-500{
  height: 570px;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
